@media (max-width: 1036px) {
    .navRequestServiceLink {
        width: 100%;
        display: inherit;
        text-decoration: none;
    }
  }

  .otherPagesMobileUiBannerStyle {
    display: none;
  }

  @media (max-width: 500px) {
    .otherPagesMobileUiBannerStyle {
      display: block;
      width: 100%;
    }
  }
  
  body {
    font-family: "Nunito Sans", sans-serif;
  }

  button {
    cursor: pointer;
  }